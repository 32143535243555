<template>
    <div class="support">
        <h5>客服设置</h5>

        <div class="mt-3">

                <form action="" @submit="onSubmit">
                    <div class="row">
                    <div class="col-md-3 col-sm-6">
                        <div class="form-group">
                            <label for="" class="form-label">客服微信号</label>
                            <input type="text" name="wechat" class="form-control" v-model="wechat">
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary">保存</button>
                        </div>
                    </div>
                    </div>
                </form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                wechat: ''
            }
        },
        methods: {
            onSubmit(e) {
                e.preventDefault();
                console.log(this.wechat)
            }
        }
    }
</script>